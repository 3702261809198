import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const MyEditor = ({ placeholder, onTextChange, wordLimit, customWidth, customHeight }) => {
  const [editorHtml, setEditorHtml] = useState('');

  const handleTextChange = (content) => {
    const words = content.trim().split(/\s+/); // Split content into words
    if (words.length <= wordLimit) {
      setEditorHtml(content);
    }
    onTextChange(content);
  };

  return (
    <div style={{ width: customWidth, height: customHeight }}>
      <ReactQuill
        style={{ height: '80%' }}
        value={editorHtml}
        onChange={handleTextChange}
        modules={{
          toolbar: [],
        }}
        formats={['list']}
        placeholder={placeholder || "Write something amazing..."}
      />
      {wordLimit && editorHtml.split(/\s+/).length > wordLimit && (
        <div style={{ color: 'red' }}>Word limit exceeded</div>
      )}
    </div>
  );
};

export default MyEditor;

