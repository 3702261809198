import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import Layout from '../layout/Layout';
import '../styles/about.css';

const About = () => {
  return (
    <Layout>
      <div className='pdfForm'>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
        <Card variant="elevation" elevation={3} style={{ marginBottom: '20px', width: '750px', height: '550px'}}>
          <CardContent>
            <div className='welcome'>
            <Typography variant="h4" gutterBottom style={{ color: '#2c2c2c' }}>  {/* Adjust heading size and color */}
              Welcome to the Achieva Person Centered Planning Tool:
            </Typography>
            </div>



            <div className="divider2"></div>

            
            <Typography variant="body1" style={{fontSize:'25px', marginTop:'40px'}}>
              Where we believe in the power of inclusion and celebration of diversity! We are dedicated to creating inclusive, accessible, and beautiful brochures that highlight the unique stories and experiences of individuals with intellectual disabilities.
              Transforming ordinary brochures into extraordinary showcases of the incredible individuals who make up our community. We ensure that their stories are told with the respect, compassion, and attention they deserve.
            </Typography>
          </CardContent>
        </Card>
        <Card variant="elevation" elevation={3} style={{ marginBottom: '20px', width: '750px', height:'550px'}}>  {/* Example of a different card variant */}
          <CardContent>

          <div className='chooseus'>
            <Typography variant="h4" gutterBottom style={{ color: '#2c2c2c' }}>
            Why Choose Us:
            </Typography>
          </div>

            <div className="divider2"></div>

            <Typography variant="body1" style={{fontSize:'25px', marginTop:'40px'}}>
            We understand the challenges faced by individuals with disabilities. Our platform is built with empathy, ensuring that your experience is not just informative but also supportive.
            Inclusivity is our priority. We strive to create a space where every individual, regardless of their abilities, feels heard, represented, and empowered.
            We are committed to continuously improving our platform based on user feedback. Your experience matters, and we're dedicated to making it better every day.           </Typography>
          </CardContent>
        </Card>
      </div>
      </div>
    </Layout>
  );
};

export default About;
