import React, { useState } from 'react';
import { degrees, PDFDocument, rgb, StandardFonts, download } from 'pdf-lib'
import pdfurl from '../pdfAppend/ppopdf.pdf'
import Quilleditor from '../React-Quill/React-Quill'
import myCustomFont from './Muli.ttf'; 
import fontkit from '@pdf-lib/fontkit'


//This function i created to check the bytes in the uint8array to determine if the file is a png or jpeg file. 
function checkImageFormat(uint8Array) {
  if (
    uint8Array[11] === 'j' &&
    uint8Array[12] === 'p' &&
    uint8Array[13] === 'e' &&
    uint8Array[14] === 'g') {
    return 'jpeg';
  }
  if (
    uint8Array[11] === 'p' &&
    uint8Array[12] === 'n' &&
    uint8Array[13] === 'g'
  ) {
    return 'png';
  }
  return null;
}





async function modifyPdf(quillText, quillText1, quillText2, selectedQuillImage, selectedQuillImage1, selectedQuillImage2) {
  // Fetch an existing PDF document
  const existingPdfBytes = await fetch(pdfurl).then(res => res.arrayBuffer());

  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes);


  // Get the first page of the document
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];


      // Get the width and height of the first page
  const { width, height } = firstPage.getSize();


 




  if(selectedQuillImage!=null){
  //Appends portrait
  const imageFormat = checkImageFormat(selectedQuillImage);


  
  if (imageFormat === 'jpeg') {
    console.log('The image is in JPEG format');
    const image = await pdfDoc.embedJpg(selectedQuillImage);
    firstPage.drawImage(image, {
      x: 560, // X-coordinate
      y: 375, // Y-coordinate 575 other
      width: 200, // Image width
      height: 200, // Image height
      //rotate: degrees(-90)
    });
  } else if (imageFormat === 'png') {
    console.log('The image is in PNG format');
    const image = await pdfDoc.embedPng(selectedQuillImage);
    firstPage.drawImage(image, {
      x: 560, // X-coordinate
      y: 375, // Y-coordinate
      width: 200, // Image width
      height: 200, // Image height
    });
  } else {
    console.log('The image format is not recognized');
  }
  }






 //appends the wahts important pic
 if(selectedQuillImage1!=null){
  const imageFormat1 = checkImageFormat(selectedQuillImage1);


  
  if (imageFormat1 === 'jpeg') {
    console.log('The image is in JPEG format');
    const image = await pdfDoc.embedJpg(selectedQuillImage1);
    firstPage.drawImage(image, {
      x: 45, // X-coordinate
      y: 15, // Y-coordinate 575 other
      width: 170, // Image width
      height: 170, // Image height
    });
  } else if (imageFormat1 === 'png') {
    console.log('The image is in PNG format');
    const image = await pdfDoc.embedPng(selectedQuillImage1);
    firstPage.drawImage(image, {
      x: 45, // X-coordinate
      y: 15, // Y-coordinate
      width: 170, // Image width
      height: 170, // Image height
    });
  } else {
    console.log('The image format is not recognized');
  }
 }
//appends the what people like and admire about me


if(selectedQuillImage2!=null){
const imageFormat2 = checkImageFormat(selectedQuillImage2);


  
if (imageFormat2 === 'jpeg') {
  console.log('The image is in JPEG format');
  const image = await pdfDoc.embedJpg(selectedQuillImage2);
  firstPage.drawImage(image, {
    x: 335, // X-coordinate
    y: 480, // Y-coordinate 575 other
    width: 125, // Image width
    height: 125, // Image height
    //rotate: degrees(-90)
  });
} else if (imageFormat2 === 'png') {
  console.log('The image is in PNG format');
  const image = await pdfDoc.embedPng(selectedQuillImage2);
  firstPage.drawImage(image, {
    x: 335, // X-coordinate
    y: 480, // Y-coordinate
    width: 125, // Image width
    height: 125, // Image height
  });
} else {
  console.log('The image format is not recognized');
}
}



pdfDoc.registerFontkit(fontkit);

// Load the custom font data (assuming you have it loaded as an ArrayBuffer)
const customFontBytes = await fetch(myCustomFont).then(res => res.arrayBuffer());

const customFont = await pdfDoc.embedFont(customFontBytes);


// First textbox appended

  const text = quillText;

  // Define the maximum line length
  const maxLineLength = 40;
  const maxLines = 17;
  // Split the text into lines based on the maximum line length and spaces
  const words = text.split(' ');
  const lines = [];
  let line = '';
  let lineCount = 0;

  for (const word of words) {
    if (lineCount < maxLines) {
      if (line.length + word.length < maxLineLength) {
        if (line !== '') {
          line += ' ' + word;
        } else {
          line += word;
        }
      } else {
        lines.push(line);
        line = word;
        lineCount++;
      }
    } else {
      break; // Stop drawing lines when maxLines is reached
    }
  }

  // Add the last line if there is any remaining text
  if (line) {
    lines.push(line);
  }

  // Draw the text on the page
  let y = height - 35;
  for (const line of lines) {
    firstPage.drawText(line, {
      x: 25,
      y,
      size: 10,
      font: customFont,
      color: rgb(0, 0, 0),
    });
    y -= 15; // Adjust the vertical position for the next line
  }



// second textbox appended


  const text1 = quillText1;

  // Define the maximum line length
  const maxLineLength1 = 40;
  const maxLines1 = 18;
  // Split the text into lines based on the maximum line length and spaces
  const words1 = text1.split(' ');
  const lines1 = [];
  let line1 = '';
  let lineCount1 = 0;

  for (const word1 of words1) {
    if (lineCount1 < maxLines1) {
      if (line1.length + word1.length < maxLineLength1) {
        if (line1 !== '') {
          line1 += ' ' + word1;
        } else {
          line1 += word1;
        }
      } else {
        lines1.push(line1);
        line1 = word1;
        lineCount1++;
      }
    } else {
      break; // Stop drawing lines when maxLines is reached
    }
  }

  // Add the last line if there is any remaining text
  if (line1) {
    lines1.push(line1);
  }

  let y1 = height - 300;

  // Draw the text on the page
  for (const line1 of lines1) {
    firstPage.drawText(line1, {
      x: 290,
      y: y1,
      size: 10,
      font: customFont,
      color: rgb(0, 0, 0),
    });
    y1 -= 15; // Adjust the vertical position for the next line
  }




// third textbox appended


const text2 = quillText2;

// Define the maximum line length
const maxLineLength2 = 40;
const maxLines2 = 7;
// Split the text into lines based on the maximum line length and spaces
const words2 = text2.split(' ');
const lines2 = [];
let line2 = '';
let lineCount2 = 0;

for (const word2 of words2) {
  if (lineCount2 < maxLines2) {
    if (line2.length + word2.length < maxLineLength2) {
      if (line2 !== '') {
        line2 += ' ' + word2;
      } else {
        line2 += word2;
      }
    } else {
      lines2.push(line2);
      line2 = word2;
      lineCount2++;
    }
  } else {
    break; // Stop drawing lines when maxLines is reached
  }
}

// Add the last line if there is any remaining text
if (line2) {
  lines2.push(line2);
}

let y2 = height - 460;

// Draw the text on the page
for (const line2 of lines2) {
  firstPage.drawText(line2, {
    x: 555,
    y: y2,
    size: 10,
    font: customFont,
    color: rgb(0, 0, 0),
  });
  y2 -= 15; // Adjust the vertical position for the next line
}





  
  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();


        return pdfBytes;
};
export default modifyPdf;
