import React, { useState, useEffect } from 'react';
import {
  Card,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Popover,
  Typography,
  Checkbox,
} from '@mui/material/';
import Layout from '../layout/Layout';
import ImageUpload from '../ImageUpload/ImageUpload';
import RichTextEditor from '../React-Quill/React-Quill';
import '../styles/home.css';
import modifyPdf from '../pdfAppend/pdfAppend';
import modifyPdfnl from '../pdfAppend2/pdfAppend2';
import parse from 'html-react-parser';

const Home = () => {
  // Hooks for Textboxes
  const [text, setText] = useState('');
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');
  const [text4, setText4] = useState('');

  // Hooks for images
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);

  // Hooks for checkboxes
  const [showImageUpload1, setShowImageUpload1] = useState(false);
  const [showImageUpload2, setShowImageUpload2] = useState(false);

  // Hooks for pdf editor iframe
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfContent, setPdfContent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  // Function for opening iframe
  const handleOpenPdfModal = (event) => {
    setAnchorEl(event.currentTarget);
    handleSendPDF();
  };

  // Function for closing iframe
  const handleClosePdfModal = () => {
    setAnchorEl(null);
  };

  // Function for downloading The PDF

  // Function for image upload component
  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };
  const handleImageSelect1 = (image) => {
    setSelectedImage1(image);
  };
  const handleImageSelect2 = (image) => {
    setSelectedImage2(image);
  };

  // Functions for textboxes
  const handleTextChange = (newText) => {
    setText(newText);
  };

  const handleTextChange1 = (newText1) => {
    setText1(newText1);
  };

  const handleTextChange2 = (newText2) => {
    setText2(newText2);
  };

  const handleTextChange3 = (newText3) => {
    setText3(newText3);
  };

  const handleTextChange4 = (newText4) => {
    setText4(newText4);
  };

  // This function parses the html from the textboxes and converts it into plain text.
  const removePTags = (html, html1, html2) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const doc1 = parser.parseFromString(html1, 'text/html');
    const doc2 = parser.parseFromString(html2, 'text/html');
    const textContent = doc.body.textContent || '';
    const textContent1 = doc1.body.textContent || '';
    const textContent2 = doc2.body.textContent || '';
    return { plainText: textContent, plaintext1: textContent1, plaintext2: textContent2 };
  };

  // This function handles the sending of the pdf
  const handleSendPDF = async () => {
    const { plainText, plaintext1, plaintext2 } = removePTags(text, text1, text2);
    const pdfBytes = await modifyPdfnl(plainText, plaintext1, plaintext2, selectedImage, selectedImage1, selectedImage2); // Correctly passing the arguments
    setPdfContent(pdfBytes);
  };

  // This will send the pdf through the backend API.
  const handleSendPdfEmail = async () => {
    const blob = new Blob([pdfContent], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'generated-pdf.pdf';
    a.click();

    try {
      const { plainText, plaintext1, plaintext2 } = removePTags(text, text1, text2);

      const pdfBytes = await modifyPdf(plainText, plaintext1, plaintext2, selectedImage, selectedImage1, selectedImage2);
      const formData = new FormData();
      formData.append('pdf', new Blob([pdfBytes], { type: 'application/pdf' }));
      formData.append('text3', text3);
      formData.append('text4', text4);

      // Dynamically set the URL based on the current location protocol
      const protocol = window.location.protocol; // 'http:' or 'https:'
      const host = protocol === 'https:' ? 'onepageprofile.achieva.info' : '192.168.200.31:3001';
      const url = `${protocol}//${host}/uploadPdf`;
      
      // Send the PDF to the server
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error sending PDF:', errorText);
        alert('Please email to PersonPlanningPortal@achieva.info');
      } else {
        alert('PDF sent successfully');
      }
    } catch (error) {
      console.error(error);
      alert('Please email to PersonPlanningPortal@achieva.info');
    }
  };

  return (
    <Layout>
      <div className="pdfGenerator">
        <Card variant="outlined" className="cardContent">
          <h3>Email</h3>
          <RichTextEditor placeholder="Email" onTextChange={handleTextChange3} wordLimit={40} customHeight={'60px'} customWidth={'500px'} />
          <div className="fullNameTitle">
            <h3>Full Name</h3>
          </div>

          <div className='fullNameTextbox'>
          <RichTextEditor placeholder="Full Name" onTextChange={handleTextChange4} wordLimit={40} customHeight={'60px'} customWidth={'500px'} />
          </div>


          <div className="divider"></div>


          <h3>Image Upload (Portrait)</h3>
          <div className="portrait">
            <ImageUpload onImageSelect={handleImageSelect} />
          </div>

          <div className="divider"></div>



          <h3>What's Important to Me</h3>
          <RichTextEditor placeholder="What's important to me..." onTextChange={handleTextChange} wordLimit={100} customHeight={'300px'} customWidth={'500px'} />

          

          
          <Checkbox checked={showImageUpload1} onChange={() => setShowImageUpload1(!showImageUpload1)} />
          
          <div className="imageUpload1">
          <h3>Would you like to add an image to this section?</h3>
          </div>



          {showImageUpload1 && (
            <>
              <h3>Image Upload (Whats Important To Me)</h3>
              <div className="likeandAdmire">
                <ImageUpload onImageSelect={handleImageSelect1} />
              </div>
            </>
          )}

          <div className="divider"></div>


  

          <h3>What People Like and Admire About Me</h3>
          <RichTextEditor placeholder="What people like and admire about me..." onTextChange={handleTextChange1} wordLimit={100} customHeight={'300px'} customWidth={'500px'} />




          
          <Checkbox checked={showImageUpload2} onChange={() => setShowImageUpload2(!showImageUpload2)} />

          <div className='imageUpload2'>
          <h3>Would you like to add an image to this section?</h3>
          </div>

          {showImageUpload2 && (
            <>
              <h3>Image Upload (What People Like And Admire About Me)</h3>
              <div className="howtoBest">
                <ImageUpload onImageSelect={handleImageSelect2} />
              </div>
            </>
          )}


          <div className="divider"></div>
          

          <h3>How Best To Support Me</h3>
          <div className="bestSupport">
            <RichTextEditor placeholder="How best to support me..." onTextChange={handleTextChange2} wordLimit={40} customHeight={'300px'} customWidth={'500px'} />
          </div>


          
          <div className="disclaimer">
            <Card variant="outlined" className="disclaimer">
              <div className="disclaimerBackground">
                <div className="disclaimerText">
                  Upon hitting the "Download" button, a copy of the PDF will be automatically sent to an Achieva email address for record-keeping purposes. Achieva reserves the right to use, store, and process the information provided for internal purposes only.
                  If you do not agree with these terms, please refrain from using the download feature.
                </div>
              </div>
            </Card>
          </div>
        </Card>
      </div>
      <div className="sendPDF">
      <div className="viewPDF">
      <Button
        variant="contained"
        onClick={handleOpenPdfModal}
        style={{ fontSize: '30px', padding: '10px' }}
      >
        Generate Brochure
      </Button>
    </div>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClosePdfModal}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Button variant="contained" onClick={handleSendPdfEmail}>
                Download and Send
              </Button>
              <Button variant="contained" onClick={handleClosePdfModal}>
                Close
              </Button>
              <div style={{ width: '100%', height: '100%' }}>
                <iframe src={URL.createObjectURL(new Blob([pdfContent], { type: 'application/pdf' }))} width="1000px" height="1000px" title="PDF Viewer"></iframe>
              </div>
              
            </Popover>
          </div>
    </Layout>
  );
};

export default Home;
