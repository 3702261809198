//AAA
// Navbar.js
import React from 'react';
import './Navbar.css';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import achievaLogo from './Achieva-Logo.png';

const Navbar = () => {

  const navigate = useNavigate();

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={achievaLogo} alt="achievaLogo" onClick={() => navigate('/')}/>
      </div>
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/about">About</Link>
        </li>
        <li className="nav-item">
          <a href="https://www.achieva.info/">Achieva Info</a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;