// Footer.js

import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          {/* Your footer logo or image */}
        </div>
      </div>
      <div className="footer-info">
        <p>&copy; {new Date().getFullYear()} Achieva</p>
        <p>Contact: techsupport@achieva.info</p>
      </div>
    </footer>
  );
}

export default Footer;
